import React from "react";
//import React, { Component } from 'react';
import "antd/dist/antd.css";
import "./css/home.css";
import Datasources from "./datasources.js";
import { Layout, Menu, Breadcrumb } from "antd";
import { AiFillStar } from "react-icons/ai";
import { FaHardHat } from "react-icons/fa";
import { BsGraphUp } from "react-icons/bs";
import { ReactSession } from "react-client-session";
//import {AiOutlineUser} from "react-icons/ai";
//import {ReactSession} from 'react-client-session';
//import Home from './components/home.js';
//import ReactDOM from 'react-dom';
const { Content, Sider } = Layout;

class Sidebar extends React.Component {
  constructor(props) {
    ReactSession.setStoreType("sessionStorage");
    super(props);
    this.state = {
      //Login: sessionStorage.getItem("isUserLogged"),
      authenticated: ReactSession.get('token') !== undefined,
    };
  }

  // clear(event) {
  //   ReactSession.setStoreType('localStorage')
  //   sessionStorage.removeItem('isUserLogged')
  //   //  ReactSession.remove("login");
  //   ReactSession.remove('email')
  //   ReactSession.remove('qrcode')
  //   window.location.reload()
  //   event.preventDefault()
  // }

  getToken = (e) => {

    /**
     * Pass the token on to Polaris.
     * todo: disable proxy when assets loaded
     */
    const app = e.target;
    const token = ReactSession.get('token');

    /**
    * Use a polaris message.
    */
    let pm = {
      caller: 'awaar',      // not used yet
      receiver: 'polaris',  // not used yet
      intent: 'token',
      status: 2,
      data: {
        token: token,
      }
    };

    app.contentWindow.postMessage(pm, "*");

    /**
     * disable proxy service worker that was used in validate
     * to get polaris assets
     * */
    //navigator.serviceWorker.getRegistrations().then( rs => { for(let r of rs) { r.unregister(); } });
  };

  render() {
    /*  const menu = (
    <Menu>
      <Menu.Item>
        <a rel="noopener noreferrer" href="/profile">
          My Profile
        </a>
      </Menu.Item>
      <Menu.Item>
        <a target="_blank" onClick={this.clear} rel="noopener noreferrer" href="/logout">
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );
*/

    return (
      <Layout>
        {this.state.authenticated ? (
          <Sider width={300} className="side-bar">
            <Menu
              className="sidenav_menubar"
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
            >
              <Datasources />
            </Menu>
            <span className="Center" style={{ display: "flex" }}>
              <span className="icon1">
                <AiFillStar />
              </span>
              <span className="icon2">
                <FaHardHat />
              </span>
              <span className="icon3">
                <BsGraphUp />
              </span>
            </span>
          </Sider>
        ) : null}
        <Layout style={{ padding: "0 15px 15px" }}>
          <Breadcrumb style={{ margin: "16px 24px" }}>
            <Breadcrumb.Item>Awaar</Breadcrumb.Item>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
          </Breadcrumb>
          <Content className="site-layout-background">
            {this.state.authenticated ? (
              <iframe
                id="app-polaris"
                title="Polaris"
                style={{ border: "none", height: "100%", width: "100%" }}
                src="/polaris"
                onLoad={this.getToken}
              >
                AWAAR
              </iframe>
            ) : (
              <div>
                {'Welcome to AWAAR { Advanced Well Analysis And Response }'} <br />
              </div>
            )}
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default Sidebar;
