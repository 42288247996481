import React, { Component } from "react";
import "./css/App.css";
import axios from "axios";
import { Form, Icon, Input, Button, Breadcrumb, message, Layout } from "antd";
import qs from "qs";
import { ReactSession } from "react-client-session";
import Head from "./header.js";
const FormItem = Form.Item;

class Verifyform extends Component {
  constructor(props) {
    ReactSession.setStoreType("sessionStorage");
    super(props);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        var details = {
          token: values.token,
          email: ReactSession.get("email"),
        };
        axios({
          method: "put",
          url: "/auth/verify",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: qs.stringify(details),
        }).then(function (response) {
          if (response.data.status === 500) {
            message.error(response.data.message);
          } else {
            message.success(response.data.message);
            ReactSession.remove("qrurl");
            setTimeout(() => {
              window.location = "/home";
            }, 800);
          }
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    ReactSession.setStoreType("sessionStorage");
    return (
      <>
        <Layout>
          <Head />
        </Layout>
        <Layout>
          <Breadcrumb style={{ margin: "16px 24px" }}>
            <Breadcrumb.Item>Awaar</Breadcrumb.Item>
            <Breadcrumb.Item>Login</Breadcrumb.Item>
            <Breadcrumb.Item>Verify</Breadcrumb.Item>
          </Breadcrumb>
        </Layout>
        <div className={"lContainer"}>
          <div className="lItem">
            <div className="loginForm">
              <h2>Two step verification</h2>
              <Form onSubmit={this.handleSubmit} className="login-form">
                <FormItem>
                  <h5>Scan the qrcode below to verify your account</h5>
                  <img
                    width={200}
                    alt="qrcode"
                    src={ReactSession.get("qrurl")}
                  />
                  <h5>Please enter the OTP below</h5>
                </FormItem>
                <FormItem>
                  {getFieldDecorator("token", {
                    rules: [
                      { required: true, message: "Please enter your Token" },
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      type="text"
                      placeholder="Token"
                      autoComplete="off"
                    />
                  )}
                </FormItem>
                <FormItem>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Log in
                  </Button>
                </FormItem>
              </Form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const Verify = Form.create()(Verifyform);

export default Verify;
