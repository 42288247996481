import React, { Component } from "react";
import "antd/dist/antd.css";
import "./css/home.css";
import { Layout, Menu, Button, Dropdown } from "antd";
import { ReactSession } from "react-client-session";
import { AiOutlineUser } from "react-icons/ai";
const { Header } = Layout;

class Head extends Component {
  constructor(props) {
    ReactSession.setStoreType("sessionStorage");
    super(props);
    this.state = {
      //Login: sessionStorage.getItem("isUserLogged"),
      authenticated: ReactSession.get('token') !== undefined,
    };
  }

  clear(event) {
    event.preventDefault();
    sessionStorage.clear();
    window.location = "/home";
  }

  render() {
    const menu = (
      <Menu>
        <Menu.Item>
          <a rel="noopener noreferrer" href="/profile">
            My Profile
          </a>
        </Menu.Item>
        <Menu.Item>
          <a
            target="_blank"
            onClick={this.clear}
            rel="noopener noreferrer"
            href="/logout"
          >
            Logout
          </a>
        </Menu.Item>
      </Menu>
    );

    return (
      <Layout>
        <Header className="header">
          <div className="logo">AWAAR</div>
          <Menu theme="dark" mode="horizontal">
            <Menu.Item key="1">
              {" "}
              <a className="home1" href="/home">
                Home
              </a>
            </Menu.Item>
            {this.state.authenticated ? null : (
              <Menu.Item key="3">
                <a className="login1" href="/login">
                  Login
                </a>
              </Menu.Item>
            )}
            {this.state.authenticated ? null : (
              <Menu.Item key="2">
                <a className="signup1" href="/signup">
                  Signup
                </a>
              </Menu.Item>
            )}
          </Menu>
          {this.state.authenticated && (
            <Dropdown overlay={menu} placement="topRight" arrow>
              <Button className="profile_btn">
                <AiOutlineUser />
              </Button>
            </Dropdown>
          )}
        </Header>
      </Layout>
    );
  }
}

export default Head;
