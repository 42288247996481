import React, { Component } from "react";
import "./css/App.css";
import axios from "axios";
import { Form, Icon, Input, Button, message, Layout, Breadcrumb } from "antd";
import qs from "qs";
import { ReactSession } from "react-client-session";
import Head from "./header.js";
import PasswordStrengthMeter from "./PasswordStrengthMeter";
import { withRouter } from "react-router-dom";
const FormItem = Form.Item;

class ResetPassword extends Component {
  constructor(props) {
    ReactSession.setStoreType("sessionStorage");
    super(props);
    this.state = {
      password: "",
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const params = this.props.location.pathname.split("/").slice(2);
    console.log(params);
    this.props.form.validateFields((err, values) => {
      var email = params[0];
      if (email === "") {
        this.setState({ email: { validateStatus: "success", errorMsg: null } });
      } else {
        var details = {
          email,
          password: values.password,
        };
        axios({
          method: "post",
          url: "/auth/resetpassword",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: qs.stringify(details),
        }).then((response) => {
          if (response.data.status === 500) {
            message.error(response.data.message);
          } else {
            message.success(response.data.message);
            setTimeout(() => {
              window.location = "/login";
            }, 800);
          }
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { password } = this.state;
    const validatePassword = (rule, value, callback) => {
      const { form } = this.props;
      if (value && value !== form.getFieldValue("password")) {
        callback("Password and confirm password are not same");
      } else {
        callback();
      }
    };
    return (
      <>
        <Layout>
          <Head />
        </Layout>
        <Layout>
          <Breadcrumb style={{ margin: "16px 24px" }}>
            <Breadcrumb.Item>Awaar</Breadcrumb.Item>
            <Breadcrumb.Item>Signup</Breadcrumb.Item>
          </Breadcrumb>
        </Layout>
        <>
          <div className={"lContainer"}>
            <div className="lItem">
              <div className="loginForm d-flex justify-content-center align-items-center">
                <h2>Reset Password</h2>
                <Form
                  onSubmit={this.handleSubmit}
                  className="login-form rounded p-4 p-sm-3"
                >
                  <FormItem>
                    {getFieldDecorator("password", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter your Password",
                        },
                      ],
                    })(
                      <Input.Password
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        visibilityToggle={true}
                        autoComplete="off"
                        type="password"
                        onChange={(e) =>
                          this.setState({ password: e.target.value })
                        }
                        placeholder="Password"
                      />
                    )}
                  </FormItem>

                  <PasswordStrengthMeter password={password} />

                  <FormItem>
                    {getFieldDecorator("confirm password", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter your Password again",
                        },
                        { validator: validatePassword },
                      ],
                    })(
                      <Input.Password
                        dependencies={["password"]}
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        visibilityToggle={true}
                        type="password"
                        placeholder="Confirm Password"
                      />
                    )}
                  </FormItem>
                  <FormItem>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                    >
                      Reset Password
                    </Button>

                    <br />
                  </FormItem>
                </Form>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

const App = Form.create()(ResetPassword);

export default withRouter(App);
