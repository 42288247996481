import React, { Component } from "react";
import "./css/App.css";
import axios from "axios";
import { Form, Icon, Input, Button, Breadcrumb, message, Layout } from "antd";
import qs from "qs";
import Head from "./header.js";

const FormItem = Form.Item;
class Loginform extends Component {
  handleReset = () => {
    this.props.form.resetFields();
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ value: true });
    this.props.form.validateFields((err, values) => {
      if (!err) {
        var details = { email: values.email };
        axios({
          method: "post",
          url: "/auth/forgotpassword",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: qs.stringify(details),
        }).then((response) => {
          if (response.data.status === 500) {
            message.error(response.data.message);
            this.handleReset();
          } else {
            message.success(response.data.message);
            setTimeout(() => {
              window.location = "/emailsent";
            }, 800);
          }
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <>
        <Layout>
          <Head />
        </Layout>
        <Layout>
          <Breadcrumb style={{ margin: "16px 24px" }}>
            <Breadcrumb.Item>Awaar</Breadcrumb.Item>
            <Breadcrumb.Item>Forgot Password</Breadcrumb.Item>
          </Breadcrumb>
        </Layout>
        <>
          <div className={"lContainer"}>
            <div className="lItem">
              <div className="loginForm">
                <h2>Forgot Password</h2>
                <Form onSubmit={this.handleSubmit} className="login-form">
                  <FormItem>
                    {getFieldDecorator("email", {
                      rules: [
                        { required: true, message: "Please enter your email" },
                      ],
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="user"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder="email"
                        autoComplete="off"
                      />
                    )}
                  </FormItem>
                  <FormItem>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                    >
                      Send Confirmation
                    </Button>
                  </FormItem>
                </Form>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

const App = Form.create()(Loginform);

export default App;
