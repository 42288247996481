import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "antd/dist/antd.css";
import { ReactSession } from "react-client-session";

// on reload event the service worker loses the token
// todo: see about caching it in the service worker
// todo: build a token refresh event to keep the user logged in and decrease token lifetime
ReactSession.setStoreType('sessionStorage');
const token = ReactSession.get('token');
if (token && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage(token);
}

ReactDOM.render(<App />, document.getElementById("root"));


// const refreshToken = _ => {
//     fetch(`${BACKEND_URL}/api/refresh`, {
//         method: "POST",
//         headers: {
//         "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ username: "elie29" }),
//     })
//         .then(handleError)
//         .then(next => {
//         append(`${next.username}, token refreshed`);
//         toggleButtons(true);
//         })
//         .catch(message => clearAll(`${message} on refresh`));
// };



// // Register service worker and check if the token is ready
// navigator.serviceWorker
// // .register('/auth-proxy.js', {
// .register(`${process.env.PUBLIC_URL}/auth-proxy.js`, {
//   scope: "/",
// })
// .then(_ => navigator.serviceWorker.ready)
// // .then(_ => refreshToken())
// // .then(_ => (intervalId = setInterval(refreshToken, REFRESH_TIME_MS)))
// .catch(err => console.log(`ServiceWorker registration failed: ${err}`));
