import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./components/home.js";
import Login from "./components/login.js";
import Verify from "./components/verify.js";
import Signup from "./components/signup.js";
import Profile from "./components/profile.js";
import Forgot from "./components/forgot.js";
import Validate from "./components/validate.js";
import VerifySignUp from "./components/verifySignUp.js";
import EmailSent from "./components/emailSent.js";
import ResetPassword from "./components/resetpassword";

class Main extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/signup">
            <Signup />
          </Route>
          <Route path="/verifysignup">
            <VerifySignUp />
          </Route>
          <Route path="/verify">
            <Verify />
          </Route>
          <Route path="/validate">
            <Validate />
          </Route>
          <Route path="/profile">
            <Profile />
          </Route>
          <Route path="/forgot">
            <Forgot />
          </Route>
          <Route path="/emailsent">
            <EmailSent />
          </Route>
          <Route path="/resetpassword">
            <ResetPassword />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }
}
export default Main;
