import React, { Component } from "react";
import { TreeSelect } from "antd";
import { ReactSession } from "react-client-session";
class Datasources extends Component {
  constructor(props) {
    ReactSession.setStoreType("sessionStorage");
    super(props);
    this.state = {
      main: [],
      value: [],
    };
  }

  componentDidMount() {
    //const email = ReactSession.get("email");
    //`/user/datasources/${email}`
    fetch('/user/datasources', {
      headers: {
        'x-auth-token': ReactSession.get('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        const con = [];
        data.forEach((namespace, i) => {
          var nodes = con;
          var namesarray = namespace.split(".");
          // each name within the namespace must be placed within the hierarchy
          namesarray.forEach((name, i) => {
            var leaf = nodes.find((n) => {
              return n.title === name;
            });
            if (leaf) {
              nodes = leaf.children;
            } else {
              var children = [];
              nodes.push({
                selectable: namesarray.length - 1 <= i,
                title: `${name}`,
                value: `${namesarray.slice(0, i + 1).join(".")}`,
                children: children,
              });
              nodes = children;
            }
          });
        });
        this.setState({ main: con });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    var dsAdded = prevState.value.length
      ? this.state.value.filter((x) => !prevState.value.includes(x))
      : this.state.value;
    var dsRemoved = prevState.value.filter(
      (x) => !this.state.value.includes(x)
    );

    var app = document.getElementById("app-polaris");

    /**
     * Use a polaris message.
     */
    var message = {
      caller: 'awaar',      // not used yet
      receiver: 'polaris',  // not used yet
      intent: 'sources',
      status: 2,
      data: {
        added: dsAdded,
        removed: dsRemoved,
      }
    };

    app.contentWindow.postMessage(message, "*");
  }

  onChange = (value) => {
    this.setState({ value });
  };

  render() {
    return (
      <TreeSelect
        showSearch
        style={{ width: "100%" }}
        value={this.state.value}
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
        placeholder="Please select"
        allowClear
        multiple
        treeData={this.state.main}
        onChange={this.onChange}
      />
    );
  }
}

export default Datasources;
