import React, { Component } from "react";
import "./css/App.css";
import axios from "axios";
import { Form, Icon, Input, Button, Breadcrumb, message, Layout } from "antd";
import qs from "qs";
import { ReactSession } from "react-client-session";
import Head from "./header.js";

const FormItem = Form.Item;
class Loginform extends Component {
  constructor(props) {
    ReactSession.setStoreType("sessionStorage");
    super(props);
    this.state = {
      value: false,
    };
  }

  handleReset = () => {
    this.props.form.resetFields();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ value: true });
    this.props.form.validateFields((err, values) => {
      if (!err) {
        var details = { email: values.email, password: values.password };
        axios({
          method: "post",
          url: "/auth/login",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: qs.stringify(details),
        }).then((response) => {
          ReactSession.setStoreType("sessionStorage");
          if (response.data.status === 500) {
            message.error(response.data.message);
            this.setState({ value: false });
            this.handleReset();
          } else {
            message.success('Logged in successfully!');
            this.setState({ value: true });
            ReactSession.set("email", response.data.email);
            if (response.data.temp_secret) {
              // user needs to set up a qrl code for 2FA
              ReactSession.set("qrurl", response.data.temp_secret);
              setTimeout(() => {
                window.location = "/verify";
              }, 800);
            } else {
              // validate user via 2FA
              setTimeout(() => {
                window.location = "/validate";
              }, 800);
            }
          }
        });
      } else {
        this.setState({ value: false });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <>
        <Layout>
          <Head />
        </Layout>
        <Layout>
          <Breadcrumb style={{ margin: "16px 24px" }}>
            <Breadcrumb.Item>Awaar</Breadcrumb.Item>
            <Breadcrumb.Item>Login</Breadcrumb.Item>
          </Breadcrumb>
        </Layout>
        <>
          <div className={"lContainer"}>
            <div className="lItem">
              <div className="loginForm d-flex justify-content-center align-items-center">
                <h2>Login</h2>
                <Form onSubmit={this.handleSubmit} className="login-form">
                  <FormItem>
                    {getFieldDecorator("email", {
                      rules: [
                        { required: true, message: "Please enter your email" },
                      ],
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="user"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        autoComplete="off"
                        placeholder="email"
                      />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator("password", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter your Password",
                        },
                      ],
                    })(
                      <Input.Password
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        visibilityToggle={true}
                        autoComplete="off"
                        type="password"
                        placeholder="Password"
                      />
                    )}
                  </FormItem>
                  <FormItem>
                    <Button
                      type="primary"
                      disabled={this.state.value}
                      htmlType="submit"
                      className="login-form-button"
                    >
                      Log in
                    </Button>
                    <span className="signup_detail">
                      Not registered yet?&nbsp;
                    </span>
                    <a className="link1" href="/signup">
                      Click here
                    </a>{" "}
                    <br />
                    <span className="forgot_pass">Forgot password?&nbsp;</span>
                    <a className="link3" href="/forgot">
                      Reset here
                    </a>{" "}
                    <br />
                  </FormItem>
                </Form>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

const App = Form.create()(Loginform);

export default App;
