import React, { Component } from 'react';
import './css/App.css';
import axios from 'axios';
import {
  Form,
  Icon,
  Input,
  Button,
  message,
  Checkbox,
  Layout,
  Breadcrumb,
} from 'antd';
import qs from 'qs';
import { ReactSession } from 'react-client-session';
import Head from './header.js';
import PasswordStrengthMeter from './PasswordStrengthMeter';
const FormItem = Form.Item;

class Signupform extends Component {
  constructor(props) {
    ReactSession.setStoreType('sessionStorage');
    super(props);
    this.state = {
      email: 'null',
      password: '',
      value: false,
    };
  }

  clearInputFields = () => {
    this.props.form.resetFields();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      this.setState({ value: true });
      const { email, name, password } = values;
      const confirmPass = values['confirm password'];
      if (!email || !name || !password || !confirmPass) {
        console.log(confirmPass);
        console.log('we hit if');
        this.setState({ value: false });
      } else {
        console.log(email);
        console.log('we hit else');

        var details = {
          name: values.name,
          email: values.email,
          password: values.password,
          phone: values.phone,
          company: values.company,
          check: values.check,
        };
        axios({
          method: 'post',
          url: '/user/signup',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data: qs.stringify(details),
        }).then((response) => {
          if (response.data.status === 500) {
            message.error(response.data.message);
            this.setState({ value: false });
            setTimeout(() => {
              this.clearInputFields();
            }, 1000);
          } else {
            message.success(response.data.message);
            this.setState({ value: true });
            setTimeout(() => {
              window.location = '/emailverifymessage';
            }, 800);
          }
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { email, password } = this.state;
    const validatePassword = (rule, value, callback) => {
      const { form } = this.props;
      if (value && value !== form.getFieldValue('password')) {
        callback('Password and confirm password are not same');
      } else {
        callback();
      }
    };
    return (
      <>
        <Layout>
          <Head />
        </Layout>
        <Layout>
          <Breadcrumb style={{ margin: '16px 24px' }}>
            <Breadcrumb.Item>Awaar</Breadcrumb.Item>
            <Breadcrumb.Item>Signup</Breadcrumb.Item>
          </Breadcrumb>
        </Layout>
        <>
          <div className={'lContainer'}>
            <div className="lItem">
              <div className="loginForm d-flex justify-content-center align-items-center">
                <h2>Signup</h2>
                <Form
                  onSubmit={this.handleSubmit}
                  className="login-form rounded p-4 p-sm-3"
                >
                  <FormItem>
                    {getFieldDecorator('name', {
                      rules: [
                        { required: true, message: 'Please enter your name' },
                      ],
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="user"
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                        type="text"
                        placeholder="Name"
                        autoComplete="off"
                      />
                    )}
                  </FormItem>
                  <FormItem
                    validateStatus={email.validateStatus}
                    help={email.errorMsg}
                  >
                    {getFieldDecorator('email', {
                      rules: [
                        { required: true, message: 'Please enter your email' },
                      ],
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="mail"
                            value="12"
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                        type="text"
                        placeholder="email"
                        autoComplete="off"
                      />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('password', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter your Password',
                        },
                      ],
                    })(
                      <Input.Password
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                        visibilityToggle={true}
                        autoComplete="off"
                        type="password"
                        onChange={(e) =>
                          this.setState({ password: e.target.value })
                        }
                        placeholder="Password"
                      />
                    )}
                  </FormItem>

                  <PasswordStrengthMeter password={password} />

                  <FormItem>
                    {getFieldDecorator('confirm password', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter your Password again',
                        },
                        { validator: validatePassword },
                      ],
                    })(
                      <Input.Password
                        dependencies={['password']}
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                        visibilityToggle={true}
                        type="password"
                        placeholder="Confirm Password"
                      />
                    )}
                  </FormItem>

                  <FormItem>
                    {getFieldDecorator(
                      'phone'
                      // {
                      //   rules: [
                      //     {
                      //       required: true,
                      //       message: 'Please enter your Phone Number',
                      //     },
                      //   ],
                      // }
                    )(
                      <Input
                        prefix={
                          <Icon
                            type="phone"
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                        type="text"
                        placeholder="Contact number"
                        autoComplete="off"
                      />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator(
                      'company'
                      // {
                      //   rules: [
                      //     {
                      //       required: true,
                      //       message: "Please enter your company's name",
                      //     },
                      //   ],
                      // }
                    )(
                      <Input
                        prefix={
                          <Icon
                            type="laptop"
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                        type="text"
                        placeholder="Company's name"
                        autoComplete="off"
                      />
                    )}
                  </FormItem>

                  <FormItem>
                    {getFieldDecorator('check', { valuePropName: 'checked' })(
                      <Checkbox name="check" style={{ lineHeight: '32px' }}>
                        Are you interested in learning more?
                      </Checkbox>
                    )}
                  </FormItem>

                  <FormItem>
                    <Button
                      type="primary"
                      disabled={this.state.value}
                      htmlType="submit"
                      className="login-form-button"
                    >
                      Signup
                    </Button>
                    <span className="login_detail">
                      Already registered?&nbsp;
                    </span>
                    <a className="link" href="/login">
                      Click here
                    </a>
                    <br />
                  </FormItem>
                </Form>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

const App = Form.create()(Signupform);

export default App;
