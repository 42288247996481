import React, { Component } from "react";
import "./css/App.css";
import { Form, Breadcrumb, Layout } from "antd";
import Head from "./header.js";
import { withRouter } from "react-router-dom";

class EmailSent extends Component {
  render() {
    return (
      <>
        <Layout>
          <Head />
        </Layout>
        <Layout>
          <Breadcrumb style={{ margin: "16px 24px" }}>
            <Breadcrumb.Item>Awaar</Breadcrumb.Item>
            <Breadcrumb.Item>Login</Breadcrumb.Item>
          </Breadcrumb>
        </Layout>
        <>
          <div className={"lContainer"}>
            <div className="lItem">
              <div className="loginForm d-flex justify-content-center align-items-center">
                <h2>Password Reset Link Sent</h2>
                <h3>Please follow the link in your email</h3>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

const App = Form.create()(EmailSent);

export default withRouter(App);
