import React, { Component } from "react";
import "antd/dist/antd.css";
import "./css/home.css";
import { Layout } from "antd";
import Head from "./header.js";
import Sidebar from "./sidebar.js";

class Home extends Component {
  render() {
    return (
      <>
        <Layout>
          <Head />
          <Sidebar />
        </Layout>
      </>
    );
  }
}
export default Home;
