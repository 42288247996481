import React, { Component } from "react";
import "./css/App.css";
import axios from "axios";
import { Form, Button, Breadcrumb, Layout, message } from "antd";
import Head from "./header.js";
import { withRouter } from "react-router-dom";

const FormItem = Form.Item;
class VerifySignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: false,
    };
  }

  componentDidMount() {
    const params = this.props.location.pathname.split("/").slice(2);
    const url = "/auth/emailVerification";
    axios({
      method: "post",
      url: `${url}/${params[0]}/${params[1]}`,
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({ value: true });
          message.success(response.data.message);
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        message.error(error.data.message);
      });
    console.log(params);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    setTimeout(() => {
      window.location = "/login";
    }, 300);
  };

  render() {
    // const { getFieldDecorator } = this.props.form

    return (
      <>
        <Layout>
          <Head />
        </Layout>
        <Layout>
          <Breadcrumb style={{ margin: "16px 24px" }}>
            <Breadcrumb.Item>Awaar</Breadcrumb.Item>
            <Breadcrumb.Item>Login</Breadcrumb.Item>
          </Breadcrumb>
        </Layout>
        <>
          <div className={"lContainer"}>
            <div className="lItem">
              <div className="loginForm d-flex justify-content-center align-items-center">
                {this.state.value ? (
                  <>
                    <h2>Email Verified Successfully</h2>
                    <h4 style={{ color: "green" }}>Please login</h4>
                  </>
                ) : (
                  <>
                    <h2>Email Verification Failed</h2>
                    <h4 style={{ color: "red" }}>
                      Please login to generate a new verification link
                    </h4>
                  </>
                )}
                <Form onSubmit={this.handleSubmit} className="login-form">
                  <FormItem>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                    >
                      Click here to Log in
                    </Button>
                    <span className="signup_detail">
                      Not registered yet?&nbsp;
                    </span>
                    <a className="link1" href="/signup">
                      Click here
                    </a>{" "}
                    <br />
                    <span className="forgot_pass">Forgot password?&nbsp;</span>
                    <a className="link3" href="/forgot">
                      Reset here
                    </a>{" "}
                    <br />
                  </FormItem>
                </Form>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

const App = Form.create()(VerifySignUp);

export default withRouter(App);
